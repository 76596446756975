import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { Tooltip } from 'refreshed-component/atoms/Tooltip';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

import { AssetCategory, LocalStorage, hooks } from '@aircarbon/utils-common';
import { formatter } from '@aircarbon/utils-common';

import { AccountDetails } from 'components/SelectOboAccount';

import { Entity } from 'state/entity';
import { User } from 'state/user';

import useQueryParams from 'hooks/useQueryParams';

import { BrandingEntity } from 'data-provider/entity';

import OrderNotification from '../OrderNotification';
import { TVChartContainer } from '../chart/TVChartContainer';
import Button from '../components/Button';
import Dialog, { DialogContext, DialogProps } from '../components/Dialog';
import { MonkeyTestingModeContext } from '../components/MonkeyController';
import MonkeyTestingForTradingScreen from '../components/MonkeyTestingForTradingScreen';
import Popover from '../components/Popover';
import { ProjectButton } from '../components/ProjectButton';
import { useOrderBook, useTradeSettings, usePairs, useSpotTrades, useOtherTrades } from '../hooks';
import { PlaceOrderContext, PlaceOrderContextProps } from '../panels/PlaceOrderCarbon';
import Watchlist from '../panels/Watchlist';
import SimpleBar from './../components/Simplebar';

const { useOnClickOutside } = hooks;

export type Layouts = 'mobile' | 'tablet' | 'mediumDesktop' | 'largeOrWideDesktop';

export const MainFrame = styled.div<{ screenSize?: string; backgroundColor: string; borderColor: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  color: #fff;
  overflow: auto;
  background-color: ${(props) => props.backgroundColor};
  border-top: 1px solid ${(props) => props.borderColor};
  box-sizing: border-box;
  min-height: 100%;
`;

export const List = styled.div<{ type: 'row' | 'column'; borderColor: string }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  ${(props) =>
    props.type === 'row'
      ? `
      flex-direction: row;
    > div {
      position: relative;
      width: 100%;
      border-right: 1px solid ${props.borderColor};
      &:last-child {
        border-right: 0px;
      }
    }
  `
      : `
      flex-direction: column;
    > div {
      position: relative;
      height: 100%;
      border-bottom: 1px solid ${props.borderColor};
      &:last-child {
        border-bottom: 0px;
      }
    }
  `}
`;

export const Box = styled.div<{ size?: number | 'auto' }>`
  ${(props) => {
    if (props.size === 'auto') {
      return `flex: 1 1 auto;`;
    } else {
      return `flex: ${props.size || 1} 1 0%;`;
    }
  }}
  box-sizing: border-box;
`;

export const Label = styled.div<{ color: string; bold?: boolean }>`
  color: ${(props) => props.color};
  display: contents;
  font-size: 15px;
  white-space: pre;
  font-weight: ${(props) => (props.bold ? `500` : `400`)};
`;

export const PairButton = styled(Button)<{
  layout?: Layouts;
  isPairVisible: boolean;
  entity: BrandingEntity;
}>`
  background: var(${Colors.gray_50});
  color: var(${Colors.gray_900});
  padding: 3px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  outline: none;
  box-sizing: border-box;
  overflow: hidden;
  transition: none;
  width: ${({ layout }) => (layout === 'mobile' ? `100%` : `280px`)};
  height: 100%;
  border-radius: 9px;
  cursor: pointer;
  border: ${({ isPairVisible }) => `1px solid var(${!isPairVisible ? Colors.gray_300 : Colors.secondaryDefault})`};
  white-space: pre;
  display: flex;
  display: flex;
  flex-direction: row;
  margin: ${({ layout }) => (layout === 'largeOrWideDesktop' ? `0` : `0.5rem`)};

  &:hover {
    border: 1px solid ${({ entity }) => entity?.theme?.web?.brandColor};
  }
  > div {
    overflow: hidden;
    > div:first-child > div:last-child {
      overflow: hidden;
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .logo {
    background: var(${Colors.gray_0});
    padding: 3px;
    border-radius: 50px;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    img {
      height: 100%;
    }
  }
`;

const isChartVisibleKey = 'trading-screen-chart-visible';

export type AssetCategoryCode = 'token' | 'biofuel' | 'fct';

const TradingHook = (props: { projectId?: string; projectName?: string; assetCategoryId?: string } = {}) => {
  const history = useHistory();
  const { entity } = Entity.useContainer();
  const {
    selector: { getAccountAddress, getUserId, getFullName },
  } = User.useContainer();
  const monkeyTestingModeContext: any = useContext(MonkeyTestingModeContext);
  const queryParams = useQueryParams();
  const [pair, setPair] = useState<string | undefined>();
  const [assetCategory, setAssetCategory] = useState<AssetCategory>(
    Number(props.assetCategoryId || queryParams.get('assetCategoryId')),
  );
  const projectId = props.projectId || (assetCategory === AssetCategory.fct ? queryParams.get('projectId') : undefined);

  const [smTopPanel, setSmTopPanel] = useState<string | undefined>();
  const [isPairVisible, showPairDropdown] = useState<boolean>(false);
  const [dialogProps, setDialogProps] = useState<DialogProps | undefined>();
  const [placeOrderData, setPlaceOrderData] = useState<PlaceOrderContextProps['placeOrderData']>();
  const { pathname } = useLocation();

  useEffect(() => {
    setAssetCategory(Number(props.assetCategoryId));
  }, [props.assetCategoryId]);

  useMemo(() => {
    if (placeOrderData) {
      setTimeout(() => setPlaceOrderData(undefined), 1000);
    }
  }, [placeOrderData]);

  const [isChartVisible, setChartVisible] = useState<boolean>(
    LocalStorage.getItem<boolean>(isChartVisibleKey, true, true),
  );
  const { data: tradeSettings } = useTradeSettings(
    { orderBy: { timeInForceOrderBy: 'uiOrder ASC' }, projectId: projectId || undefined },
    AssetCategory[assetCategory] as AssetCategoryCode,
  );

  const foundPair = tradeSettings?.pairs?.find((item) => item.name === pair);
  const currentPair = foundPair || tradeSettings?.pairs?.[0];

  useEffect(() => {
    if (!tradeSettings) return;
    const queryPair = queryParams.get('pair')?.split('_').join('/');
    const firstPair = tradeSettings?.pairs?.[0]?.name;
    const currentQueryPair = tradeSettings?.pairs?.find((item) => item.name === queryPair)?.name;

    const pairOnPriority =
      tradeSettings?.pairs?.find((item) => item.name === pair)?.name || currentQueryPair || firstPair;

    if (pairOnPriority !== pair && pairOnPriority) {
      setPair(pairOnPriority);
    }

    const [token, currency] = pairOnPriority?.split('/') || [];
    const query = [
      assetCategory ? `assetCategoryId=${assetCategory}` : undefined,
      token && currency ? `pair=${token}_${currency}` : undefined,
      projectId ? `projectId=${projectId}` : undefined,
    ]
      .filter((item) => item)
      .join('&');
    if (query && history.location.search !== `?${query}`) {
      window.history.replaceState(undefined, '', `${pathname}${!!query ? `?${query}` : ''}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, history.location.search, pair, tradeSettings?.pairs, assetCategory, queryParams, history]);

  const currentCcy = currentPair?.name?.split('/')[1];
  // TODO: support pagination
  const { orderBook, isLoadingFirstTime } = useOrderBook(currentPair?.id);
  const { trades } = useSpotTrades({
    pairId: currentPair?.id,
    status: 'confirmed',
    page: 1,
    limit: 20,
    assetCategory: AssetCategory[assetCategory] as AssetCategoryCode,
  });

  const { trades: otherTrades } = useOtherTrades({
    pairId: currentPair?.id,
    status: 'confirmed',
    page: 1,
    limit: 20,
    assetCategory: AssetCategory[assetCategory] as AssetCategoryCode,
  });

  const { pairs: pairsWatchList } = usePairs({
    assetCategories: [AssetCategory[assetCategory]] as Array<AssetCategoryCode>,
    includeMarketData: true,
    projectId: projectId || undefined,
  });
  const watchlist = pairsWatchList
    ? {
        items: pairsWatchList,
        total: pairsWatchList?.length || 0,
      }
    : null;
  const orderBookViewState = LocalStorage.getItem<'TopAndBottom' | 'LeftAndRight'>(
    'trading-screen-order-book-view',
    'LeftAndRight',
  );
  const orderBookLevelState = LocalStorage.getItem<'OB-L2' | 'OB-L3'>('trading-screen-order-book-level', 'OB-L3');
  const [orderBookView, setOrderBookView] = useState(orderBookViewState);
  const [orderBookLevel, setOrderBookLevel] = useState(orderBookLevelState);
  useEffect(() => {
    LocalStorage.setItem('trading-screen-order-book-view', orderBookView);
  }, [orderBookView]);

  useEffect(() => {
    LocalStorage.setItem('trading-screen-order-book-level', orderBookLevel);
  }, [orderBookLevel]);

  const [selectedAccount, setSelectedAccount] = useState<AccountDetails>({
    account: getAccountAddress(),
    userId: getUserId(),
    fullName: getFullName(),
  });

  const changeUrl = ({ pair }: { pair?: string | undefined }) => {
    if (pair) queryParams.set('pair', pair.split('/').join('_'));
    else if (pair === '') queryParams.delete('pair');
    setPair(pair);
    const url = new URL(window.location.href);
    url.search = queryParams.toString();
    window.history.pushState(queryParams.toString(), document.title, url.toString());
  };

  useEffect(() => {
    showPairDropdown(false);
  }, [pair]);

  useEffect(() => {
    LocalStorage.setItem(isChartVisibleKey, isChartVisible, true);
  }, [isChartVisible]);

  const pairWatchItem = watchlist?.items?.find((item) => item.pairId === currentPair?.id);

  const tokenNumDecimals = currentPair?.baseAsset?.numDecimals ?? 0;
  const ccyNumDecimals = currentPair?.quoteAsset?.numDecimals ?? 2;

  const pairDropdownHolderRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(pairDropdownHolderRef, () => {
    if (pairDropdownHolderRef) showPairDropdown(false);
  });

  const lastBidPrice = pairWatchItem?.marketData?.lastBidPrice;
  const lastAskPrice = pairWatchItem?.marketData?.lastAskPrice;
  const lastTradedPrice = pairWatchItem?.marketData?.lastTradedPrice;

  const tickerArea = (layout?: Layouts) => {
    const contents = (
      <div
        className={`w-full h-auto flex flex-row  whitespace-pre ${
          layout === 'mobile' ? 'gap-y-4 gap-x-8 flex-wrap justify-between' : `gap-9 items-center`
        }`}
      >
        <div className="flex flex-col flex-shrink text-sm">
          <Text color={Colors.gray_500} size={FontSize.xs}>
            Daily Last <Tooltip text="Daily last traded price starting at 00:00 UTC" />
          </Text>
          <Text color={Colors.gray_900} size={FontSize.base} weight={FontWeight.medium}>
            {lastTradedPrice === null ||
            lastTradedPrice === undefined ||
            Number.isNaN(lastTradedPrice) ||
            (lastTradedPrice === 0 && assetCategory === AssetCategory.token)
              ? '-'
              : formatter.formatNumber(lastTradedPrice)}
          </Text>
        </div>
        <div className="flex flex-col flex-shrink text-sm">
          <Text color={Colors.gray_500} size={FontSize.xs}>
            Bid
          </Text>
          <Text color={Colors.gray_900} size={FontSize.base} weight={FontWeight.medium}>
            {lastBidPrice === null ||
            lastBidPrice === undefined ||
            Number.isNaN(lastBidPrice) ||
            (lastBidPrice === 0 && assetCategory === AssetCategory.token)
              ? '-'
              : formatter.formatNumber(lastBidPrice)}
          </Text>
        </div>
        <div className="flex flex-col flex-shrink text-sm">
          <Text color={Colors.gray_500} size={FontSize.xs}>
            Ask
          </Text>
          <Text color={Colors.gray_900} size={FontSize.base} weight={FontWeight.medium}>
            {lastAskPrice === null ||
            lastAskPrice === undefined ||
            Number.isNaN(lastAskPrice) ||
            (lastAskPrice === 0 && assetCategory === AssetCategory.token)
              ? '-'
              : formatter.formatNumber(lastAskPrice)}
          </Text>
        </div>
        <div className="flex-col flex-shrink text-sm">
          <div className="flex flex-row items-center">
            <Text color={Colors.gray_500} size={FontSize.xs}>
              Daily Change <Tooltip text="Daily change starting at 0:00 UTC" />
            </Text>
          </div>
          <div>
            <Text color={Colors.gray_900} size={FontSize.base} weight={FontWeight.medium}>
              {pairWatchItem?.marketData?.changePercentage ? (
                <>
                  {pairWatchItem?.marketData?.changeValue
                    ? formatter.formatCurrency(pairWatchItem?.marketData?.changeValue, ccyNumDecimals)
                    : ''}{' '}
                  {pairWatchItem?.marketData?.changePercentage
                    ? `(${formatter.formatNumber(pairWatchItem.marketData.changePercentage)}%)`
                    : ''}
                </>
              ) : (
                '-'
              )}
            </Text>
          </div>
        </div>
        <div className="flex-col flex-shrink text-sm">
          <div className="flex flex-row items-center">
            <Text color={Colors.gray_500} size={FontSize.xs}>
              Daily High <Tooltip text="Daily high starting at 0:00 UTC" />
            </Text>
          </div>
          <div>
            <Text color={Colors.gray_900} size={FontSize.base} weight={FontWeight.medium}>
              {pairWatchItem?.marketData?.highPrice ||
              (pairWatchItem?.marketData?.highPrice === 0 && assetCategory === AssetCategory.biofuel)
                ? formatter.formatCurrency(pairWatchItem?.marketData?.highPrice, ccyNumDecimals)
                : '-'}
            </Text>
          </div>
        </div>
        <div className="flex-col flex-shrink text-sm">
          <div className="flex flex-row items-center">
            <Text color={Colors.gray_500} size={FontSize.xs}>
              Daily Low <Tooltip text="Daily low starting at 0:00 UTC" />
            </Text>
          </div>
          <div>
            <Text color={Colors.gray_900} size={FontSize.base} weight={FontWeight.medium}>
              {pairWatchItem?.marketData?.lowPrice ||
              (pairWatchItem?.marketData?.lowPrice === 0 && assetCategory === AssetCategory.biofuel)
                ? formatter.formatCurrency(pairWatchItem?.marketData?.lowPrice, ccyNumDecimals)
                : '-'}
            </Text>
          </div>
        </div>
        <div className="flex-col flex-shrink text-sm">
          <div className="flex flex-row items-center">
            <Text color={Colors.gray_500} size={FontSize.xs}>
              Daily Volume ({currentPair?.baseAsset?.uom?.name}) <Tooltip text="Daily volume starting at 0:00 UTC" />
            </Text>
          </div>
          <div>
            <Text color={Colors.gray_900} size={FontSize.base} weight={FontWeight.medium}>
              {pairWatchItem?.marketData?.volume
                ? formatter.formatNumber(pairWatchItem?.marketData?.volume, tokenNumDecimals)
                : '-'}
            </Text>
          </div>
        </div>
      </div>
    );

    return (
      <div className="w-full h-full">
        <div className={`h-full w-full`}>
          <SimpleBar
            className={`${layout !== 'mobile' ? 'h-full w-full pl-6 pr-6 flex flex-row justify-center' : 'p-6'}`}
          >
            {contents}
          </SimpleBar>
        </div>
      </div>
    );
  };
  const pairButtonRef = useRef<HTMLDivElement>(null);

  const pairDropdown = (layout: Layouts) => {
    return (
      <div
        className={`relative flex ${
          layout === 'mobile' ? 'flex-col' : 'flex-row'
        } flex-auto items-center h-full w-full`}
      >
        <div
          style={{
            width: layout === 'mobile' ? 'calc(100% - 4px)' : '',
          }}
          className="flex flex-shrink justify-center items-center"
        >
          {pairButton(layout)}
        </div>
        {tickerArea(layout)}
      </div>
    );
  };

  const pairButton = (layout?: Layouts) => {
    return (
      <Popover
        trigger={'click'}
        placement={layout === 'mobile' ? 'bottomRight' : 'bottomLeft'}
        visible={isPairVisible}
        content={
          <div
            ref={isChartVisible ? pairDropdownHolderRef : undefined}
            style={{
              width: pairButtonRef.current ? `${pairButtonRef.current.offsetWidth - 22}px` : '330px',
              minWidth: '300px',
              padding: '1rem',
            }}
          >
            <Watchlist
              position="realative"
              watchlist={watchlist}
              onClick={(pairName) => {
                setPair(pairName);
                showPairDropdown(false);
              }}
              size={'big'}
              backgroundColor={`var(${Colors.gray_0})`}
              style={{
                overflow: 'hidden',
                height: (watchlist?.total ?? 0) > 10 ? '300px' : 'auto',
              }}
              assetCategory={AssetCategory[assetCategory] as AssetCategoryCode}
            />
          </div>
        }
      >
        <PairButton
          onClick={() => showPairDropdown(!isPairVisible)}
          entity={entity}
          isPairVisible={isPairVisible}
          layout={layout}
          ref={pairButtonRef}
          onTouchStart={(event) => {
            if (isPairVisible) {
              event.stopPropagation();
            }
          }}
          onMouseDown={(event) => {
            if (isPairVisible) {
              event.stopPropagation();
            }
          }}
          config={{
            arrow: true,
            arrowIcon: <Icon color={Colors.gray_900} width={12} height={12} type={IconType.ChevronDown} />,
            label: (
              <div className="flex relative gap-3 items-center text-left">
                <div
                  style={{
                    borderColor: entity?.theme?.web?.brandColor,
                  }}
                  className="logo"
                >
                  <img src={`/logos/${currentPair?.baseAsset?.name}.png`} alt="" />
                </div>
                <div>
                  <Text color={Colors.gray_900} weight={FontWeight.semibold} size={FontSize.base}>
                    {currentPair?.name}
                  </Text>
                  <Text color={Colors.gray_500} size={FontSize.xs}>
                    {currentPair?.baseAsset?.fullName}
                  </Text>
                </div>
              </div>
            ),
            size: 'big',
          }}
        />
      </Popover>
    );
  };

  const projectButton = (layout?: Layouts) => {
    if (layout === 'largeOrWideDesktop') {
      return (
        <div className="h-auto">
          <ProjectButton layout={layout} entity={entity} apxName={props.projectName} />
        </div>
      );
    }

    return false;
  };

  const renderChartPan = (
    isChartVisible = true,
    layout?: 'mobileLayout' | 'tabletLayout' | 'mediumDesktopLayout' | 'largeOrWideDesktopLayout',
  ) => {
    return (
      <>
        {isChartVisible && (
          <div className="relative h-full">
            {currentPair?.symbol && currentPair?.name && currentPair?.id && <TVChartContainer pair={currentPair} />}
          </div>
        )}
      </>
    );
  };

  const holder = (layout: JSX.Element) => {
    return (
      <OrderNotification orderBookPairId={currentPair?.id}>
        <DialogContext.Provider
          value={{
            props: dialogProps,
            showDialog: (props?: DialogProps) => {
              setDialogProps(props);
            },
            removeDialog: () => {
              setDialogProps(undefined);
            },
          }}
        >
          <PlaceOrderContext.Provider
            value={{
              setPlaceOrderData,
              placeOrderData,
            }}
          >
            <div className="overflow-hidden relative w-full h-full">
              {monkeyTestingModeContext.isVisible && (
                <MonkeyTestingForTradingScreen
                  accountAddress={getAccountAddress()}
                  pairId={currentPair?.id}
                  pair={currentPair?.name ?? ''}
                  userApiKey={''}
                />
              )}
              {layout}
            </div>
            {dialogProps && <Dialog {...dialogProps} />}
          </PlaceOrderContext.Provider>
        </DialogContext.Provider>
      </OrderNotification>
    );
  };

  return {
    states: {
      smTopPanel,
      setSmTopPanel,
      pair,
      assetCategory: AssetCategory[assetCategory] as AssetCategoryCode,
      showPairDropdown,
      orderBookView,
      setOrderBookView,
      isChartVisible,
      setChartVisible,
      orderBookLevel,
      setOrderBookLevel,
      selectedAccount,
      setSelectedAccount,
    },
    info: {
      watchlist,
      tradeSettings,
      orderBook,
      trades,
      otherTrades,
      isLoadingFirstTime,
      pairDropdownHolderRef,
      tokenNumDecimals,
      ccyNumDecimals,
      pairWatchItem,
      currentPair,
      currentCcy,
    },
    utils: { changeUrl },
    views: { holder, pairDropdown, pairButton, projectButton, tickerArea, renderChartPan },
  };
};

export default TradingHook;
