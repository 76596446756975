import { toProjectStatus } from 'refreshed-pages/project-exchange/utils/toProjectStatus';
import { toVintagePeriod } from 'refreshed-pages/project-exchange/utils/toVintagePeriod';

import { ApxProject } from '@aircarbon/utils-common/src/dto';

export const toProject = (props: { apxProject: ApxProject }) => {
  const { apxProject } = props;

  return {
    id: apxProject.id as number,
    title: apxProject.name,
    status: toProjectStatus(apxProject.status.code),
    date: toDate({
      createdAt: apxProject.createdAt,
      updatedAt: apxProject.updatedAt,
    }),
    dateLabel: apxProject.updatedAt ? 'Updated at' : 'Created at',
    vintage: toVintagePeriod(apxProject.vintages),
    country: apxProject.country?.name || apxProject.country?.code || '-',
    sectoralScope: apxProject.sectoralScope?.name || '-',
    ccbStandards: (apxProject.ccbStandards?.length
      ? apxProject.ccbStandards
      : [
          {
            id: '-',
            name: '-',
          },
        ]
    )
      .map((standard) => standard.name)
      .join(', '),
  };
};

const toDate = (props: { createdAt?: string; updatedAt?: string }) => {
  if (!props.createdAt && !props.updatedAt) {
    return '-';
  }

  const projectDate = new Date((props.updatedAt || props.createdAt) as string);

  return projectDate.toLocaleDateString('en-US');
};
