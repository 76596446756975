import { useState } from 'react';
import { Colors } from 'refreshed-component/design-system';

import { AccountDetails } from 'components/SelectOboAccount';

import { User } from 'state/user';

import { checkIfNonBilateral } from 'utils/checkIfTokenOrRec';

import Panel from '../components/Panel';
import OrderBook from '../panels/OrderBook';
import OrderHistory from '../panels/OrderHistory';
import PlaceOrderBiofuel from '../panels/PlaceOrderBiofuel';
import PlaceOrderCarbon from '../panels/PlaceOrderCarbon';
import Trades from '../panels/Trades';
import Watchlist from '../panels/Watchlist';
import TradingHook, { MainFrame } from './trading.hook';

const LAYOUT = 'mobile';

const MobileLayout = ({ viewPort }: { viewPort: string }) => {
  const {
    selector: { getAccountAddress, getFullName, getUserId },
  } = User.useContainer();
  const {
    states: { orderBookView, setOrderBookView, setSmTopPanel, orderBookLevel, setOrderBookLevel, assetCategory },
    info: { watchlist, tradeSettings, orderBook, trades, isLoadingFirstTime, currentPair, currentCcy, pairWatchItem },
    views: { holder, pairDropdown },
  } = TradingHook();

  const [selectedAccount, setSelectedAccount] = useState<AccountDetails>({
    account: getAccountAddress(),
    userId: getUserId(),
    fullName: getFullName(),
  });

  const isNonBilateral = checkIfNonBilateral(assetCategory);

  const layout = (
    <MainFrame
      backgroundColor={`var(${Colors.gray_0})`}
      borderColor={`var(${Colors.gray_200})`}
      screenSize={`${viewPort}`}
    >
      <div
        style={{
          minHeight: '100%',
        }}
        className="flex flex-col"
      >
        <div
          className="flex flex-shrink"
          style={{
            borderBottom: `1px solid ${`var(${Colors.gray_200})`}`,
          }}
        >
          {pairDropdown(LAYOUT)}
        </div>
        <div
          className="flex flex-auto"
          style={{
            minWidth: '280px',
            backgroundColor: `var(${Colors.gray_0})`,
            borderBottom: `1px solid ${`var(${Colors.gray_200})`}`,
          }}
        >
          {
            <Panel
              height="auto"
              key={viewPort}
              selected="Place Order"
              onChange={(item) => {
                setSmTopPanel(item);
              }}
              list={[
                {
                  title: 'Place Order',
                  content:
                    assetCategory === 'biofuel' ? (
                      <PlaceOrderBiofuel
                        layout={LAYOUT}
                        height="auto"
                        pairs={tradeSettings?.pairs ?? []}
                        pair={currentPair?.name}
                        timeInForces={tradeSettings?.timeInForces ?? []}
                        ccyAsset={tradeSettings?.ccyAssets?.[currentCcy as any]}
                        lastTradedPrice={orderBook?.lastTradedPrice}
                        pairWatchlist={pairWatchItem}
                      />
                    ) : (
                      <PlaceOrderCarbon
                        selectedAccount={selectedAccount}
                        setSelectedAccount={setSelectedAccount}
                        layout={LAYOUT}
                        height="auto"
                        pairs={tradeSettings?.pairs ?? []}
                        pair={currentPair?.name}
                        timeInForces={tradeSettings?.timeInForces ?? []}
                        ccyAsset={tradeSettings?.ccyAssets?.[currentCcy as any]}
                        lastTradedPrice={orderBook?.lastTradedPrice}
                        pairWatchlist={pairWatchItem}
                      />
                    ),
                },
                {
                  title: isNonBilateral ? 'Order Book' : 'Order Matching',
                  content: (
                    <>
                      <OrderBook
                        layout={LAYOUT}
                        theme={'gray'}
                        pair={currentPair}
                        style={{
                          height: '340px',
                          position: 'relative',
                        }}
                        isLoading={isLoadingFirstTime}
                        orderBook={orderBook ?? []}
                        view={orderBookView}
                        onViewChanges={(view) => setOrderBookView(view)}
                        level={orderBookLevel}
                        onLevelChanges={(level) => setOrderBookLevel(level)}
                        assetCategory={assetCategory}
                      />
                    </>
                  ),
                },
                {
                  title: 'Markets',
                  content: (
                    <Watchlist
                      backgroundColor={`var(${Colors.gray_0})`}
                      withCustomScrollBar={false}
                      watchlist={watchlist}
                      assetCategory={assetCategory}
                    />
                  ),
                },
              ]}
            />
          }
        </div>
        <div
          className="flex flex-auto"
          style={{
            backgroundColor: `var(${Colors.gray_0})`,
            overflowX: 'auto',
          }}
        >
          <OrderHistory
            selectedAccount={selectedAccount}
            height="auto"
            key={viewPort}
            pair={currentPair}
            layout={LAYOUT}
            showPnL={assetCategory === 'biofuel' ? false : true}
            list={[
              {
                title: isNonBilateral ? 'Market Trades' : 'Confirmed Matches',
                content: (
                  <Trades
                    style={{
                      height: 'auto',
                    }}
                    withCustomScrollBar={false}
                    trades={trades}
                    baseAssetId={currentPair?.baseAsset?.id}
                    assetCategory={assetCategory}
                  />
                ),
              },
            ]}
            assetCategory={assetCategory}
          />
        </div>
      </div>
    </MainFrame>
  );

  return holder(layout);
};

export default MobileLayout;
