import { useQuery } from 'react-query';

import { AssetCategoryCode } from '@aircarbon/utils-common';

import { TradeSettingParams, fetchTradeSettings } from 'data-provider/trade/fetchTradeSettings';

export function useTradeSettings(params: TradeSettingParams, assetCategory: AssetCategoryCode = 'token') {
  const { orderBy } = params;
  const { data, isLoading, error } = useQuery(['oms-trade', orderBy.timeInForceOrderBy, assetCategory], () =>
    fetchTradeSettings(params, assetCategory),
  );

  return {
    error,
    isLoading,
    data,
  };
}
