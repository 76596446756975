import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'refreshed-component/atoms/Button';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Text } from 'refreshed-component/atoms/Text';
import { FontSize, FontWeight } from 'refreshed-component/design-system';
import { PageHeader } from 'refreshed-component/molecules/PageHeader';
import { useProjectsListFilteringOptions } from 'refreshed-pages/project-exchange/hooks/useProjectsListFilteringOptions';
import { useSearchResultsPage } from 'refreshed-pages/project-exchange/hooks/useSearchResultsPage';
import styled from 'styled-components';

import { ApxProject } from '@aircarbon/utils-common/src/dto';

import { User } from 'state/user';

import { toFiltersFromQueryParams } from '../../utils/toFiltersFromQueryParams';
import { toQueryParamsFromFilters } from '../../utils/toQueryParamsFromFilters';
import { ProjectsList, ProjectsListFilters } from './components/ProjectsList';
import { Project } from './utils/Project';
import { toMyProjectsApiParams } from './utils/toMyProjectsApiParams';
import { toProject } from './utils/toProject';

export const MyProjects = () => {
  const history = useHistory();
  const {
    selector: { getUserId },
  } = User.useContainer();

  const userId = getUserId();

  const toApiFilters = useMemo(() => toMyProjectsApiParams(userId), [userId]);

  const {
    totalResultsCount: totalProjectsCount,
    isFetching: isFetchingProjects,
    results: projects,
    searchValue,
    currentPage,
    pageSize,
    filters,
    changePagination,
    search,
    filter,
  } = useSearchResultsPage<ProjectsListFilters, ApxProject, Project>({
    searchEndpoint: '/user/apx/projects',
    urlPath: '/account/apx/my-projects',
    toQueryParams: toQueryParamsFromFilters,
    toApiFilters,
    toFilters: toFiltersFromQueryParams,
    toResults: (results) => results.map((apxProject) => toProject({ apxProject })),
  });

  const {
    filteringOptions,
    isFetching: isFetchingFilteringOptions,
    fetchFilteringOptions,
  } = useProjectsListFilteringOptions();

  useEffect(() => {
    fetchFilteringOptions();
  }, []);

  const onPressCreateProject = () => {
    history.push('/account/apx/my-projects/new-project');
  };

  const onFilter = (filters: ProjectsListFilters) => {
    filter(filters);
  };

  const onSearch = (searchValue: string) => {
    search(searchValue);
  };

  const onChangePagination = (currentPage: number, pageSize: number) => {
    changePagination(currentPage, pageSize);
  };

  return (
    <>
      <PageHeader
        title={'My projects'}
        margin={'24px'}
        suffix={
          <Button
            onClick={onPressCreateProject}
            config={{
              size: 'l',
              icon: {
                left: <Icon width={20} height={20} type={IconType.PlusCircle} />,
              },
            }}
          >
            Offer New Project
          </Button>
        }
      />

      <ProjectsList
        filteringOptions={filteringOptions}
        search={searchValue}
        filters={filters}
        onFilter={onFilter}
        projects={projects}
        currentPage={currentPage}
        pageSize={pageSize}
        totalProjectsCount={totalProjectsCount}
        areProjectsLoading={isFetchingProjects}
        areFilteringOptionsLoading={isFetchingFilteringOptions}
        onSearch={onSearch}
        onChangePagination={onChangePagination}
      />
    </>
  );
};

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
`;
