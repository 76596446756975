import { useState } from 'react';
import { Colors } from 'refreshed-component/design-system';

import { AccountDetails } from 'components/SelectOboAccount';

import { User } from 'state/user';

import { checkIfNonBilateral } from 'utils/checkIfTokenOrRec';

import Panel from '../components/Panel';
import OrderBook from '../panels/OrderBook';
import OrderHistory from '../panels/OrderHistory';
import PlaceOrderBiofuel from '../panels/PlaceOrderBiofuel';
import PlaceOrderCarbon from '../panels/PlaceOrderCarbon';
import Trades from '../panels/Trades';
import Watchlist from '../panels/Watchlist';
import TradingHook, { Box, List, MainFrame } from './trading.hook';

const LAYOUT = 'mediumDesktop';

const MediumDesktopLayout = ({ viewPort }: { viewPort: string }) => {
  const {
    selector: { getAccountAddress, getFullName, getUserId },
  } = User.useContainer();
  const {
    states: { isChartVisible, orderBookView, setOrderBookView, orderBookLevel, setOrderBookLevel, assetCategory },
    info: { watchlist, tradeSettings, orderBook, trades, isLoadingFirstTime, currentPair, currentCcy },

    views: { holder, renderChartPan, pairDropdown },
  } = TradingHook();

  const [selectedAccount, setSelectedAccount] = useState<AccountDetails>({
    account: getAccountAddress(),
    userId: getUserId(),
    fullName: getFullName(),
  });

  const isNonBilateral = checkIfNonBilateral(assetCategory);

  const layout = (
    <MainFrame backgroundColor={`var(${Colors.gray_0})`} borderColor={`var(${Colors.gray_200})`}>
      <List borderColor={`var(${Colors.gray_200})`} type="column">
        <Box size={2.5} className={'flex flex-col h-full'}>
          <List borderColor={`var(${Colors.gray_200})`} type="row">
            <Box size={4}>
              <List borderColor={`var(${Colors.gray_200})`} type="column">
                <Box style={{ minHeight: '64px', maxHeight: '64px' }} className="flex flex-col">
                  <List borderColor={`var(${Colors.gray_200})`} type="row">
                    <Box size={2} className="flex flex-col">
                      {pairDropdown(LAYOUT)}
                    </Box>
                  </List>
                </Box>
                <Box size={1} className="flex flex-col">
                  <List borderColor={`var(${Colors.gray_200})`} type="row">
                    <Box size={2}>
                      <Panel
                        key={viewPort}
                        selected={isNonBilateral ? 'Order Book' : 'Order Matching'}
                        list={[
                          {
                            title: isNonBilateral ? 'Order Book' : 'Order Matching',
                            content: (
                              <OrderBook
                                pair={currentPair}
                                isLoading={isLoadingFirstTime}
                                orderBook={orderBook ?? []}
                                layout={'tablet'}
                                view={orderBookView}
                                onViewChanges={(view) => setOrderBookView(view)}
                                level={orderBookLevel}
                                onLevelChanges={(level) => setOrderBookLevel(level)}
                                assetCategory={assetCategory}
                              />
                            ),
                          },
                          {
                            title: 'Chart',
                            content: <>{renderChartPan(isChartVisible)}</>,
                          },
                          {
                            title: 'Markets',
                            content: <Watchlist watchlist={watchlist} assetCategory={assetCategory} />,
                          },
                        ]}
                      />
                    </Box>
                  </List>
                </Box>
              </List>
            </Box>
            <Box
              size={1}
              style={{
                minWidth: '280px',
                backgroundColor: `var(${Colors.gray_0})`,
              }}
            >
              <Panel
                key={viewPort}
                selected="Place Order"
                contentBodyStyle={{
                  overflow: 'auto',
                }}
                list={[
                  {
                    title: 'Place Order',
                    style: {
                      background: `var(${Colors.gray_0})`,
                    },
                    content:
                      assetCategory === 'biofuel' ? (
                        <PlaceOrderBiofuel
                          pairs={tradeSettings?.pairs ?? []}
                          pair={currentPair?.name}
                          timeInForces={tradeSettings?.timeInForces ?? []}
                          ccyAsset={tradeSettings?.ccyAssets?.[currentCcy as any]}
                          lastTradedPrice={orderBook?.lastTradedPrice}
                          pairWatchlist={watchlist?.items?.find((item) => item.pairId === currentPair?.id)}
                        />
                      ) : (
                        <PlaceOrderCarbon
                          selectedAccount={selectedAccount}
                          setSelectedAccount={setSelectedAccount}
                          pairs={tradeSettings?.pairs ?? []}
                          pair={currentPair?.name}
                          timeInForces={tradeSettings?.timeInForces ?? []}
                          ccyAsset={tradeSettings?.ccyAssets?.[currentCcy as any]}
                          lastTradedPrice={orderBook?.lastTradedPrice}
                          pairWatchlist={watchlist?.items?.find((item) => item.pairId === currentPair?.id)}
                        />
                      ),
                  },
                ]}
              />
            </Box>
          </List>
        </Box>
        <Box>
          <List borderColor={`var(${Colors.gray_200})`} type="row">
            <Box size={4}>
              <OrderHistory
                selectedAccount={selectedAccount}
                pair={currentPair}
                layout={LAYOUT}
                assetCategory={assetCategory}
              />
            </Box>
            <Box size={1} style={{ minWidth: '280px' }}>
              <Panel
                key={viewPort}
                selected={isNonBilateral ? 'Market Trades' : 'Confirmed Matches'}
                list={[
                  {
                    title: isNonBilateral ? 'Market Trades' : 'Confirmed Matches',
                    content: (
                      <Trades trades={trades} baseAssetId={currentPair?.baseAsset?.id} assetCategory={assetCategory} />
                    ),
                  },
                ]}
              />
            </Box>
          </List>
        </Box>
      </List>
    </MainFrame>
  );

  return holder(layout);
};

export default MediumDesktopLayout;
