import { Text } from 'refreshed-component/atoms/Text';
import { Colors, FontSize, FontWeight } from 'refreshed-component/design-system';
import styled from 'styled-components';

type InformationItemProps = {
  label: string;
  value?: string;
};

export const InformationItem: React.FC<InformationItemProps> = (props) => {
  const { label, value = '-' } = props;
  return (
    <StyledInformationItem>
      <Text size={FontSize.small} weight={FontWeight.medium} color={Colors.gray_500}>
        {label}
      </Text>
      <Text size={FontSize.large} weight={FontWeight.bold}>
        {value}
      </Text>
    </StyledInformationItem>
  );
};

const StyledInformationItem = styled.div`
  display: flex;
  flex-direction: column;
`;
