import { Badge, BadgeSize, BadgeVariant } from 'refreshed-component/atoms/Badge';
import { Button } from 'refreshed-component/atoms/Button';
import { Icon, IconType } from 'refreshed-component/atoms/Icon';
import { Colors } from 'refreshed-component/design-system';
import { PeriodInformation } from 'refreshed-pages/project-exchange/components/PeriodInformation';
import { ProjectCardLayout } from 'refreshed-pages/project-exchange/components/ProjectCardLayout';
import { SDGGoals } from 'refreshed-pages/project-exchange/components/SDGGoals';
import styled from 'styled-components';

import { MarketsTable } from '../MarketsTable';
import { ProjectCardLoader } from './components/Loader';

type ProjectCardProps = {
  id: number;
  apxId: number;
  title: string;
  subtitle: string;
  description: string;
  vintageYear: string;
  isTrending: boolean;
  markets: Array<{ label: string; value: string }>;
  sdgGoals: Array<{ label: string; value: string }>;
  ccbStandards: string;
  sectoralScope: string;
  country: string;
  onPressTrade(): void;
  onPressDetails(): void;
};

export const ProjectCard: React.FC<ProjectCardProps> & {
  Loader: typeof ProjectCardLoader;
} = (props) => {
  const {
    title,
    subtitle,
    description,
    isTrending,
    country,
    ccbStandards,
    sectoralScope,
    sdgGoals,
    onPressTrade,
    onPressDetails,
  } = props;

  return (
    <ProjectCardLayout
      header={
        isTrending && (
          <BadgeContainer>
            <Badge
              variant={BadgeVariant.Default}
              size={BadgeSize.Large}
              backgroundColor={Colors.purple_100}
              color={Colors.purple_800}
              startIcon={<Icon type={IconType.Check} width={16} height={16} />}
            >
              Trending
            </Badge>
          </BadgeContainer>
        )
      }
      title={title}
      description={description}
      subtitle={subtitle}
      information={[
        {
          label: 'Country',
          value: country,
        },
        {
          label: 'Sectoral Scope',
          value: sectoralScope,
        },
        {
          label: 'CCB Standards',
          value: ccbStandards,
        },
      ]}
      footer={sdgGoals.length ? <SDGGoals goals={sdgGoals} /> : undefined}
      endSection={
        <>
          <PeriodInformation label={'Vintage Year'} value={props.vintageYear} />
          <MarketsTable markets={props.markets} />
          <ActionsContainer>
            <Button
              onClick={onPressDetails}
              config={{
                size: 'l',
                color: 'outlined',
              }}
            >
              Details
            </Button>
            <Button
              config={{
                size: 'l',
                color: 'primary',
              }}
              onClick={onPressTrade}
            >
              Trade
            </Button>
          </ActionsContainer>
        </>
      }
    />
  );
};

ProjectCard.Loader = ProjectCardLoader;

const BadgeContainer = styled.div`
  display: inline-flex;
`;

const ActionsContainer = styled.div`
  border-top: 1px solid var(${Colors.gray_200});
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 24px;
  gap: 8px;
`;
